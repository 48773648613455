
import { Vue, Component, Prop } from 'vue-property-decorator'
import { exitFullscreen, launchFullscreen } from '@/utils/formatData'

@Component
export default class Fullscreen extends Vue {
  @Prop() private fullElementId!: string
  private isFullscreen = false

  fullscreenChange () {
    this.isFullscreen = !this.isFullscreen
    if (this.isFullscreen) {
      launchFullscreen(document.getElementById(this.fullElementId))
      this.$emit('fullscreenStyleChange', true)
    } else {
      exitFullscreen()
      this.$emit('fullscreenStyleChange', false)
    }
  }
}
