// 工人，手环, 工牌
const braceletOnlineImg = require('@/assets/icon/bracelet/online.svg')
const braceletOnlineImgCursor = require('@/assets/icon/bracelet/online2.svg')
const braceletOffImg = require('@/assets/icon/bracelet/off.svg')
const braceletOffImgCursor = require('@/assets/icon/bracelet/off2.svg')
const braceletWarningImg = require('@/assets/icon/bracelet/sos.svg')
const braceletWarningImgCursor = require('@/assets/icon/bracelet/sos2.svg')
// 温湿
const soilHumidityOnlineImg = require('@/assets/icon/soilHumidity/normal.svg')
const soilHumidityOnlineImgCursor = require('@/assets/icon/soilHumidity/normal-big.svg')
const soilHumidityOffImg = require('@/assets/icon/soilHumidity/offline.svg')
const soilHumidityOffImgCursor = require('@/assets/icon/soilHumidity/offline-big.svg')
const soilHumidityWarningImg = require('@/assets/icon/soilHumidity/warning.svg')
const soilHumidityWarningImgCursor = require('@/assets/icon/soilHumidity/warning-big.svg')
// 肥力
const soilFertilityOnlineImg = require('@/assets/icon/soilFertility/normal.svg')
const soilFertilityOnlineImgCursor = require('@/assets/icon/soilFertility/normal-big.svg')
const soilFertilityOffImg = require('@/assets/icon/soilFertility/offline.svg')
const soilFertilityOffImgCursor = require('@/assets/icon/soilFertility/offline-big.svg')
const soilFertilityWarningImg = require('@/assets/icon/soilFertility/warning.svg')
const soilFertilityWarningImgCursor = require('@/assets/icon/soilFertility/warning-big.svg')
// 水质
const waterQualityOnlineImg = require('@/assets/icon/waterQuality/normal.svg')
const waterQualityOnlineImgCursor = require('@/assets/icon/waterQuality/normal-big.svg')
const waterQualityOffImg = require('@/assets/icon/waterQuality/offline.svg')
const waterQualityOffImgCursor = require('@/assets/icon/waterQuality/offline-big.svg')
// 空气质量，环境监测
const weatherQualityOnlineImg = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_zaixian.svg')
const weatherQualityOnlineImgCursor = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_zaixian_dianji.svg')
const weatherQualityOffImg = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_lixian.svg')
const weatherQualityOffImgCursor = require('@/assets/icon/weatherQuality/icon_map_huanjingjiankong_lixian_dianji.svg')
// 虫情
const insectMonitorOnlineImg = require('@/assets/icon/insectMonitor/normal.svg')
const insectMonitorOnlineImgCursor = require('@/assets/icon/insectMonitor/normal-big.svg')
const insectMonitorOffImg = require('@/assets/icon/insectMonitor/offline.svg')
const insectMonitorOffImgCursor = require('@/assets/icon/insectMonitor/offline-big.svg')
// 积水监测
const pondMonitorOnlineImg = require('@/assets/icon/pondMonitor/online.svg')
const pondMonitorOnlineImgCursor = require('@/assets/icon/pondMonitor/online2.svg')
const pondMonitorOffImg = require('@/assets/icon/pondMonitor/offline.svg')
const pondMonitorOffImgCursor = require('@/assets/icon/pondMonitor/offline2.svg')
const pondMonitorWarningImg = require('@/assets/icon/pondMonitor/alarm.svg')
const pondMonitorWarningImgCursor = require('@/assets/icon/pondMonitor/alarm2.svg')
// 智慧井盖
const manholeOnlineImg = require('@/assets/icon/manhole/normal.svg')
const manholeOnlineImgCursor = require('@/assets/icon/manhole/normal-big.svg')
const manholeOffImg = require('@/assets/icon/manhole/offline.svg')
const manholeOffImgCursor = require('@/assets/icon/manhole/offline-big.svg')
const manholeWarningImg = require('@/assets/icon/manhole/warning.svg')
const manholeWarningImgCursor = require('@/assets/icon/manhole/warning-big.svg')

// 智慧监控
const monitorOnlineImg = require('@/assets/icon/monitor/online.svg')
const monitorOnlineImgCursor = require('@/assets/icon/monitor/online2.svg')
const monitorOffImg = require('@/assets/icon/monitor/off.svg')
const monitorOffImgCursor = require('@/assets/icon/monitor/off2.svg')

const iconList = [{
// 工牌 1023
  type: 'braceletOnline',
  icon: braceletOnlineImg
}, {
  type: 'braceletOnlineSelect',
  icon: braceletOnlineImgCursor
}, {
  type: 'braceletOffline',
  icon: braceletOffImg
}, {
  type: 'braceletOfflineSelect',
  icon: braceletOffImgCursor
}, {
  type: 'braceletWarn',
  icon: braceletWarningImg
}, {
  type: 'braceletWarnSelect',
  icon: braceletWarningImgCursor
}, {
// 温湿 1001
  type: 'soilHumidityOnline',
  icon: soilHumidityOnlineImg
}, {
  type: 'soilHumidityOnlineSelect',
  icon: soilHumidityOnlineImgCursor
}, {
  type: 'soilHumidityOffline',
  icon: soilHumidityOffImg
}, {
  type: 'soilHumidityOfflineSelect',
  icon: soilHumidityOffImgCursor
}, {
  type: 'soilHumidityWarn',
  icon: soilHumidityWarningImg
}, {
  type: 'soilHumidityWarnSelect',
  icon: soilHumidityWarningImgCursor
}, {
// 肥力 1013
  type: 'soilFertilityOnline',
  icon: soilFertilityOnlineImg
}, {
  type: 'soilFertilityOnlineSelect',
  icon: soilFertilityOnlineImgCursor
}, {
  type: 'soilFertilityOffline',
  icon: soilFertilityOffImg
}, {
  type: 'soilFertilityOfflineSelect',
  icon: soilFertilityOffImgCursor
}, {
  type: 'soilFertilityWarn',
  icon: soilFertilityWarningImg
}, {
  type: 'soilFertilityWarnSelect',
  icon: soilFertilityWarningImgCursor
}, {
// 水质 1015
  type: 'waterQualityOnline',
  icon: waterQualityOnlineImg
}, {
  type: 'waterQualityOnlineSelect',
  icon: waterQualityOnlineImgCursor
}, {
  type: 'waterQualityOffline',
  icon: waterQualityOffImg
}, {
  type: 'waterQualityOfflineSelect',
  icon: waterQualityOffImgCursor
}, {
// 环境监测 1012
  type: 'weatherQualityOnline',
  icon: weatherQualityOnlineImg
}, {
  type: 'weatherQualityOnlineSelect',
  icon: weatherQualityOnlineImgCursor
}, {
  type: 'weatherQualityOffline',
  icon: weatherQualityOffImg
}, {
  type: 'weatherQualityOfflineSelect',
  icon: weatherQualityOffImgCursor
}, {
// 虫情 1017
  type: 'insectMonitorOnline',
  icon: insectMonitorOnlineImg
}, {
  type: 'insectMonitorOnlineSelect',
  icon: insectMonitorOnlineImgCursor
}, {
  type: 'insectMonitorOffline',
  icon: insectMonitorOffImg
}, {
  type: 'insectMonitorOfflineSelect',
  icon: insectMonitorOffImgCursor
}, {
// 积水监测 1024
  type: 'pondMonitorOnline',
  icon: pondMonitorOnlineImg
}, {
  type: 'pondMonitorOnlineSelect',
  icon: pondMonitorOnlineImgCursor
}, {
  type: 'pondMonitorOffline',
  icon: pondMonitorOffImg
}, {
  type: 'pondMonitorOfflineSelect',
  icon: pondMonitorOffImgCursor
}, {
  type: 'pondMonitorWarn',
  icon: pondMonitorWarningImg
}, {
  type: 'pondMonitorWarnSelect',
  icon: pondMonitorWarningImgCursor
}, {
// 智慧水井 1022
  type: 'manholeOnline',
  icon: manholeOnlineImg
}, {
  type: 'manholeOnlineSelect',
  icon: manholeOnlineImgCursor
}, {
  type: 'manholeOffline',
  icon: manholeOffImg
}, {
  type: 'manholeOfflineSelect',
  icon: manholeOffImgCursor
}, {
  type: 'manholeWarn',
  icon: manholeWarningImg
}, {
  type: 'manholeWarnSelect',
  icon: manholeWarningImgCursor
}, {
// 智慧监控 1002
  type: 'monitorOnline',
  icon: monitorOnlineImg
}, {
  type: 'monitorOnlineSelect',
  icon: monitorOnlineImgCursor
}, {
  type: 'monitorOffline',
  icon: monitorOffImg
}, {
  type: 'monitorOfflineSelect',
  icon: monitorOffImgCursor
}]

export default iconList
