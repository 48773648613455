
import { Component, Vue } from 'vue-property-decorator'
import { ProjectInfo, DeviceInfo } from '@/types/overview'
import { PolygonPathDetail } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import Fullscreen from './Fullscreen.vue'
import iconlist from './iconList'
import videojs from 'video.js'
require('video.js/dist/video-js.css')

@Component({
  name: 'Overview',
  components: { Fullscreen },
  filters: {
    numberFormat (val: number | string) {
      val = Number(val) || 0
      val = val > 10000 ? (val / 10000).toFixed(1) : val
      return val
    },
    formatWind (direction: string) {
      const windDeg = Number(direction)
      let wind = '--'
      if (windDeg <= 22.25 || windDeg > 337.75) {
        wind = '北风'
      } else if (windDeg > 22.25 && windDeg <= 67.25) {
        wind = '东北风'
      } else if (windDeg > 67.25 && windDeg <= 112.25) {
        wind = '东风'
      } else if (windDeg > 112.25 && windDeg <= 157.25) {
        wind = '东南风'
      } else if (windDeg > 157.25 && windDeg <= 202.25) {
        wind = '南风'
      } else if (windDeg > 202.25 && windDeg <= 247.25) {
        wind = '西南风'
      } else if (windDeg > 247.25 && windDeg <= 292.25) {
        wind = '西风'
      } else if (windDeg > 292.25 && windDeg <= 337.25) {
        wind = '西北风'
      }
      return wind
    }
  }
})
export default class Overview extends Vue {
  private isShowVideo = false
  private map: AMap.Map | null = null
  private mapBlockHeight = 'calc(100vh - 145px)'
  private projectInfo: ProjectInfo | {} = {}
  private currentDevice: DeviceInfo | null = null
  private markers: Array<AMap.Marker> = []
  private videoPlay: any = null

  get projectId () {
    return this.$route.params.projectId || ''
  }

  get infoName () {
    const info = this.currentDevice as DeviceInfo
    return info.type === 'bracelet' ? info.workerName : info.deviceTypeName
  }

  get infoTip () {
    const info = this.currentDevice as DeviceInfo
    let tip = ''
    if (info.deviceStatus === '1') {
      if (info.type === 'bracelet') {
        tip = info.presentStatus === '0' ? '<div class="tip">在岗</div>' : info.presentStatus === '1' ? '<div class="tip outwork">脱岗</div>' : '<div class="tip">在线</div>'
      } else {
        tip = info.runStatus === '0' ? '<div class="tip">正常</div>' : info.runStatus === '1' ? '<div class="tip warn">告警</div>' : '<div class="tip">在线</div>'
      }
    } else {
      tip = info.deviceStatus === '2' ? '<div class="tip offline">离线</div>' : ''
    }
    return tip
  }

  created (): void {
    if (this.projectId) {
      this.getProjectInfo()
    }
  }

  destroyed (): void {
    if (this.map) {
      (this.map as any).clearEvents('click')
      this.map.destroy()
    }
  }

  fullscreenStyleChange (isFull: boolean) {
    this.mapBlockHeight = isFull ? 'calc(100vh - 45px)' : 'calc(100vh - 145px)'
  }

  // 渲染地图
  loadMap (locationList: Array<PolygonPathDetail>, device: { [propName: string]: Array<DeviceInfo> }) {
    return AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.$nextTick(() => {
        this.map = new AMap.Map('map', {})
        drawPolygon(this.map, locationList, null, this.clearInfoWindow)
        this.map.on('click', this.clearInfoWindow)
        this.deviceListFormat(device)
      })
    })
  }

  getProjectInfo () {
    this.$axios.get(this.$apis.overview.selectSumProjectDetailList, {
      projectId: this.projectId
    }).then((res) => {
      this.projectInfo = res.projectDetail as ProjectInfo
      const locationList = (this.projectInfo as ProjectInfo).projectLocationList || []
      if (!this.map) {
        this.loadMap(locationList, res.resultDeivces || {})
      } else {
        this.map.clearMap()
        drawPolygon(this.map, locationList, null, this.clearInfoWindow)
        this.deviceListFormat(res.resultDeivces || {})
      }
    })
  }

  deviceListFormat (device: { [propName: string]: Array<DeviceInfo> }) {
    this.drawMarkers('bracelet', device['1023'] || [])
    this.drawMarkers('soilHumidity', device['1001'] || [])
    this.drawMarkers('soilFertility', device['1013'] || [])
    this.drawMarkers('waterQuality', device['1015'] || [])
    this.drawMarkers('weatherQuality', device['1012'] || [])
    this.drawMarkers('insectMonitor', device['1017'] || [])
    this.drawMarkers('pondMonitor', device['1024'] || [])
    this.drawMarkers('manhole', device['1022'] || [])
    this.drawMarkers('monitor', device['1002'] || [])
  }

  // 获取图片
  getIcon (type: string, data: DeviceInfo, isCurrent: boolean) {
    let name = ''
    if (['bracelet', 'soilHumidity', 'soilFertility', 'pondMonitor', 'manhole'].includes(type)) {
      name = data.deviceStatus === '1' ? data.runStatus && data.runStatus !== '0' ? 'Warn' : 'Online' : 'Offline'
    } else {
      name = data.deviceStatus === '1' ? 'Online' : 'Offline'
    }
    if (isCurrent) {
      name += 'Select'
    }
    const index = iconlist.findIndex(item => item.type === type + name)

    return index >= 0 ? iconlist[index].icon : ''
  }

  // 地图打点
  drawMarkers (type: string, list: Array<DeviceInfo>) {
    list.forEach(item => {
      if (item.longitude && item.latitude) {
        const icon = this.getIcon(type, item, false)
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: icon,
            imageSize: new AMap.Size(36, 48)
          }),
          zIndex: 3,
          offset: new AMap.Pixel(-18, -48),
          position: [Number(item.longitude), Number(item.latitude)],
          extData: { ...item, type: type }
        })
        ;(this.map as AMap.Map).add(marker)
        marker.on('click', this.deviceMarkerClick)
        this.markers.push(marker)
      }
    })
  }

  // 清空信息框相关信息
  clearInfoWindow () {
    if (this.isShowVideo) {
      this.isShowVideo = false
      this.videoPlay.dispose()
      this.videoPlay = null
    }
    if (this.currentDevice) {
      this.currentDevice = null
    }
  }

  // 点击地图设备点标记
  deviceMarkerClick (e: any) {
    const data = e.target.getExtData()
    const info = this.currentDevice ? this.currentDevice as DeviceInfo : null
    if (!(info && info.deviceId === data.deviceId)) {
      this.clearInfoWindow()
      setTimeout(() => {
        this.currentDevice = data
        this.showInfoWindow()
      }, 10)
    }
  }

  showInfoWindow () {
    this.$nextTick(() => {
      const infoWindow = new AMap.InfoWindow({
        content: this.$refs.infoWindow as any,
        isCustom: true,
        autoMove: true,
        closeWhenClickMap: true,
        anchor: 'bottom-center',
        offset: [0, -50] as any
      })
      const info = this.currentDevice as DeviceInfo
      infoWindow.open(this.map as AMap.Map, [Number(info.longitude), Number(info.latitude)])
    })
  }

  playVideo () {
    const info = this.currentDevice as DeviceInfo
    if (!this.isShowVideo && info.deviceStatus === '1') {
      this.isShowVideo = true
      this.$nextTick(() => {
        let timer: any = {}
        this.videoPlay = videojs(this.$refs.video as Element, {
          fluid: true,
          muted: true,
          controls: true,
          height: 180,
          width: 320,
          autoplay: true
        }, function onPlayerReady () {
          console.log('已就绪')
        })
        this.videoPlay.on('error', () => {
          console.log('加载错误')
          this.videoPlay.src(info.addressLive)
          this.videoPlay.play()
        })
        this.videoPlay.on('play', () => {
          console.log('onplay')
          timer = setTimeout(() => {
            this.videoPlay.pause()
          }, 1000 * 60 * 5)
        })
        this.videoPlay.on('pause', () => {
          clearTimeout(timer)
          console.log('onpause')
        })
        this.showInfoWindow()
      })
    }
  }
}
